import "./App.css";
import Box from "@mui/material/Box";
import ContactCard from "../Components/ContactCard";
import SpecialtyCard from "../Components/SpecialtyCard";
import ClientsCard from "../Components/ClientsCard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../img/logo.png";
import { Typography } from "@mui/material";
import Rellax from "rellax";

var rellax = new Rellax(".rellax");

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const MPAtheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#262262",
      light: "#4e45c7",
      dark: "#19163d",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      paper: "rgba(255,255,255,0.85)",
    },
  },
  typography: {
    fontFamily: "Inter, Arial, sans-serif",
    h3: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#262262",
      marginBottom: "10px",
      font: "Poppins",
      fontSize: "1.5rem",
    },
    h4: {
      fontWeight: 700,
      color: "#262262",
      marginBottom: "10px",
      font: "Inter",
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 500,
      color: "#4e45c7",
      fontSize: "1.3rem",
    },
    body1: {
      fontFamily: "Inter, Arial, sans-serif",
      fontSize: ".9em",
    },
    body2: {
      fontWeight: 100,
      fontSize: "1rem",
    },
  },
  shape: {
    borderRadius: 10,
  },
  shadows: [
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 1 ? "3px 8px 25px 0 rgba(0,0, 0, 0.3)" : shadow
    ),
  ],
});

function App() {
  return (
    <div className="app">
      <ThemeProvider theme={MPAtheme}>
        <div className="city">
          <div>
            <Typography variant="body1">NEW YORK </Typography>
          </div>
          <div>
            <Typography variant="body1">MIAMI</Typography>
          </div>
        </div>
        <div className="header">
          <img className="logo" src={logo} />
        </div>
        <div>
          <div className="top-section">
            <div className="specialty-card-container">
              <SpecialtyCard />
            </div>
            <div className="clients-card-container">
              <ClientsCard />
              <div className="contact-card-container">
                <ContactCard />
              </div>
            </div>
          </div>
          {/* <div className="bottom-section">
            <div className="contact-card-container">              
            </div>
          </div> */}
        </div>
        <div className="footer">
          <Typography>© 2023 MPA Advisors</Typography>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function ClientsCard() {
  return (
    <Card
      sx={{
        minwidth: "auto",
        border: 1,
        borderColor: "white",
        minHeight: "11.2em",
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div">
          Past clients
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography variant="body1">
            Damon Sharpe <br></br>
            Clinton Sparks <br></br>
            Dynasty Music <br></br>
            Luis Fonsi <br></br>
            Nicky Jam <br></br>
            Nacho <br></br>
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: "2em" }}>
            Rauw Alejandro<br></br>
            Juice WRLD<br></br>
            Liz Rose <br></br>
            Brock Berryhill <br></br>
            Ozuna <br></br>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { RiLinkedinBoxLine } from "react-icons/ri";
import { AiOutlineGlobal } from "react-icons/ai";
import { AiOutlineMobile } from "react-icons/ai";

export default function ClientsCard() {
  return (
    <Card
      sx={{
        minwidth: "auto",
        border: 1,
        borderColor: "white",
      }}
    >
      <CardContent>
        <Typography variant="h3" component="div">
          Denny Marte
        </Typography>
        <Typography variant="body1">
          <AiOutlineMobile className="icon" />
          <a href="mailto:mpaadvisorsllc@gmail.com">mpaadvisorsllc@gmail.com</a>
          <br></br>
          <AiOutlineGlobal className="icon" />
          mpaadvisors.com<br></br>
        </Typography>
        <div className="social">
          <a href="https://www.linkedin.com/in/denny-marte-7263a31b2/">
            <button className="btn-hover color">
              <RiLinkedinBoxLine />
            </button>
          </a>
        </div>
      </CardContent>
    </Card>
  );
}

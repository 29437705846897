import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function SpecialtyCard() {
  return (
    <Card
      sx={{
        minwidth: "auto",
        minHeight: "25.2em",
        border: 1,
        borderColor: "white",
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div">
          Our specialty
        </Typography>
        <Typography variant="body1">
          Brokering and consulting for sales of music publishing and masters
          assets.
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Pop</Typography>
          </li>
          <li>
            <Typography variant="body1">Urban</Typography>
          </li>
          <li>
            <Typography variant="body1">Latin</Typography>
          </li>
          <li>
            <Typography variant="body1">Country</Typography>
          </li>
        </ul>
        <Typography variant="body1">
          A 39 year career in the music entertainment industry with established
          relationships with the majors, independent publishers, and music
          investment buyers.
        </Typography>
      </CardContent>
    </Card>
  );
}
